import getConfig from 'next/config';
import { ApolloProvider } from '@apollo/client';
import cookies from './cookies';
import { JWT_COOKIE_NAME } from './constants';
import { CustomNextReq, CustomNextPageContext } from './types';
import { SETTINGS_QUERY } from './core/SettingsQuery';
import { settings } from '../core-types';
import { initializeApollo } from './apollo-client';

interface MyWindow extends Window {
  __NEXT_DATA__: any;
}

declare var window: MyWindow;

const {
  publicRuntimeConfig: { CORE_ENDPOINT },
  serverRuntimeConfig: { CORE_ENDPOINT_SERVER }
} = getConfig();

let coreEndpoint = CORE_ENDPOINT;
const connectToDevTools = process.env.NODE_ENV !== 'production';

if (CORE_ENDPOINT_SERVER) {
  coreEndpoint = CORE_ENDPOINT_SERVER;
}

function getVariables(req: CustomNextReq | null, systemId: string) {
  const parsedCookies = cookies.parse();
  const { locale } = req || window.__NEXT_DATA__.props;
  return {
    systemId,
    token: parsedCookies[JWT_COOKIE_NAME],
    locale: locale
  };
}

export function withApollo(PageComponent, { ssr = true } = {}) {
  const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
    const client =
      apolloClient ||
      initializeApollo(apolloState || {}, {
        connectToDevTools,
        coreEndpoint,
        getVariables: () => getVariables(null, pageProps.systemConfig.id)
      });
    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    // Find correct display name
    const displayName =
      PageComponent.displayName || PageComponent.name || 'Component';

    // Set correct display name for devtools
    WithApollo.displayName = `withApollo(${displayName})`;
  }

  if (ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async (ctx: CustomNextPageContext) => {
      const systemConfig = (ctx.req || window.__NEXT_DATA__.props).systemConfig;

      ctx.apolloClient = initializeApollo(
        {},
        {
          connectToDevTools,
          coreEndpoint,
          getVariables: () => getVariables(ctx.req || null, systemConfig.id),
          userAgent: ctx.req ? ctx.req.headers['user-agent'] : undefined
        }
      );

      // restore cache from systemConfig, but only on server side reqs
      if (typeof window === 'undefined' && systemConfig.apolloCache) {
        ctx.apolloClient.cache.restore(systemConfig.apolloCache);
      }

      const pageProps = PageComponent.getInitialProps
        ? await PageComponent.getInitialProps(ctx)
        : {};

      // Only on the server
      if (typeof window === 'undefined') {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return pageProps;
        }

        if (ssr) {
          try {
            const { data } = await ctx.apolloClient.query<settings>({
              query: SETTINGS_QUERY
            });

            if (!data) {
              throw new Error('Could not load settings data');
            }

            if (!data.system) {
              if (ctx.res) {
                // eslint-disable-next-line require-atomic-updates
                ctx.res.statusCode = 404;
              }
              const err = new Error('Could not find system');
              err.code = 'ENOENT';
              throw err;
            }
          } catch (error) {
            // Do not log ENOENT errors
            if (error.code !== 'ENOENT') {
              // Prevent Apollo Client GraphQL errors from crashing SSR.
              // Handle them in components via the data.error prop:
              // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
              // eslint-disable-next-line no-console
              console.error('Error while running `getDataFromTree`', error);
            }
          }
        }
      }

      // Extract query data from the Apollo store
      const apolloState = ctx.apolloClient.cache.extract();

      return {
        ...pageProps,
        apolloState
      };
    };
  }

  return WithApollo;
}
