import { useMemo } from 'react';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  NormalizedCacheObject
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import possibleTypes from './core/possible-types.json';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import { isServer } from './util';
import cookies from './cookies';
import { JWT_COOKIE_NAME } from './constants';
import { Sentry } from './sentry';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

interface Options {
  connectToDevTools: boolean;
  coreEndpoint: string;
  getVariables: () => {
    token: string | null;
    locale: string;
    systemId: string;
  };
  userAgent?: string;
}

type CoreGraphQLError = GraphQLError & {
  code: string;
};

const reportErrors = () =>
  onError(props => {
    const { graphQLErrors, networkError, operation } = props;
    if (graphQLErrors || networkError) {
      const ssr = isServer ? 'true' : 'false';
      if (graphQLErrors) {
        // const graphiqlUrl = getGraphiQLUrl(coreEndpoint, operation)
        graphQLErrors.map((error: CoreGraphQLError) => {
          const { code, message } = error;
          const errorMessage = `[GraphQL error]: ${code}: ${message}`;
          Sentry.withScope(scope => {
            scope.setFingerprint([
              'Type: GraphQLError',
              `Code: ${code}`,
              `Message: ${message}`
            ]);
            // scope.setExtra('graphiqlUrl', graphiqlUrl)
            if (networkError) {
              scope.setExtra('networkError', networkError);
            }
            scope.setTag('ssr', ssr);
            Sentry.captureException(new Error(errorMessage));
          });
        });
      } else if (networkError) {
        let message = `[Network error]: ${networkError}`;
        if (operation && operation.operationName) {
          message += `, operationName: ${operation.operationName}`;
        }
        Sentry.withScope(scope => {
          scope.setFingerprint(['Type: GraphQLNetworkError']);
          scope.setTag('ssr', ssr);
          Sentry.captureException(new Error(message));
        });
      }
    }
  });

const handleUnauthorized = () =>
  onError(({ graphQLErrors, networkError, operation, response }) => {
    if (
      graphQLErrors &&
      graphQLErrors.find(
        (error: CoreGraphQLError) => error.code === 'ERR_INVALID_AUTH_TOKEN'
      )
    ) {
      cookies.clear(null, JWT_COOKIE_NAME);
      // redirect(nextContext, nextContext.asPath)
    } else if (
      networkError &&
      'statusCode' in networkError &&
      (networkError.statusCode === 401 || networkError.statusCode === 403)
    ) {
      cookies.clear(null, JWT_COOKIE_NAME);
      // redirect(nextContext, nextContext.asPath)
    } else if (
      operation.operationName === 'currentUserQuery' &&
      response &&
      response.data &&
      response.data.currentUser === null
    ) {
      cookies.clear(null, JWT_COOKIE_NAME);
      // redirect(nextContext, `/?error=`)
    }
  });

export function getInMemoryCache() {
  return new InMemoryCache({
    possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          analytics: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            }
          },
          checkOrderCompletion: {
            merge(_existing, incoming) {
              return incoming;
            }
          },
          sanitySettings: {
            merge(_existing, incoming) {
              return incoming;
            }
          }
        }
      },
      User: {
        fields: {
          unpaidOrders: {
            merge(_existing, incoming) {
              return incoming;
            }
          },
          productRenewals: {
            merge(_existing, incoming) {
              return incoming;
            }
          }
        }
      }
    }
  });
}

function createApolloClient({
  getVariables,
  coreEndpoint,
  userAgent
}: Options) {
  const uri = `${coreEndpoint}/public/api/v1/graphql`;

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const { locale, token, systemId } = getVariables();
      return {
        headers: {
          systemId,
          locale,
          authorization: token ? `Bearer ${token}` : null,
          ...headers
        }
      };
    });
    return forward(operation);
  });

  // When https://github.com/apollographql/apollo-link-state/issues/185 is resolved, try this again
  // const stateLink = withClientState({
  //   resolvers: {},
  //   cache
  // })

  const headers = {};

  if (userAgent) {
    headers['user-agent'] = userAgent;
  }

  const httpLink = ApolloLink.from([
    new HttpLink({
      uri,
      headers
    })
  ]);
  let link = httpLink;

  if (typeof window !== 'undefined') {
    const { locale, token, systemId } = getVariables();

    // Create a WebSocket link:
    const wsLink = new GraphQLWsLink(
      createClient({
        url: `${uri.replace('http', 'ws')}/subscriptions`,
        connectionParams: {
          systemId,
          locale,
          authToken: token
        }
      })
    );

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    link = ApolloLink.split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    );
  }

  return new ApolloClient({
    name: 'public-web',
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([
      createPersistedQueryLink({ sha256, useGETForHashedQueries: true }),
      authLink,
      handleUnauthorized(),
      reportErrors(),
      link
    ]),
    cache: getInMemoryCache(),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all'
      },
      query: {
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  });
}

export function initializeApollo(
  initialState: NormalizedCacheObject | null = null,
  options: Options
) {
  const _apolloClient = apolloClient ?? createApolloClient(options);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...initialState, ...existingCache });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function useApollo(
  initialState: NormalizedCacheObject | null = null,
  options: Options
) {
  const store = useMemo(() => initializeApollo(initialState, options), [
    initialState
  ]);
  return store;
}
